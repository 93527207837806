import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guide.scss';
import { Row, Col } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';
import { WWCharacter } from '../../../modules/ww/common/components/ww-character';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const WWGuidesBeg: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ww-guide'} game="ww">
      <ul className="breadcrumb">
        <li>
          <Link to="/wuthering-waves/">Wuthering Waves</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/wuthering-waves/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Beginner guide</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ww/categories/category_beginner.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Wuthering Waves Beginner guide</h1>
          <h2>
            A beginner guide for Wuthering Waves that will help you play the
            game optimally!
          </h2>
          <p>
            Last updated: <strong>04/05/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="About the game" />
        <p>
          Wuthering Waves is a game that features open world exploration with
          rich and diverse narrative and a high degree of freedom of combat.
          Players act as Rovers, who awake after a long period of slumber,
          embark on adventures in the whole wide world made up of new era, new
          environment and new technology.
        </p>
        <p>
          Wuthering Waves is developed by <strong>Kuro Game</strong>, the
          creators of Punishing Gray Raven. The game features the best action
          RPG combat in the mobile genre and now it will be translated to an
          open world in the style of Genshin Impact.
        </p>
        <p>And here's the official introduction:</p>
        <blockquote>
          <p>
            Welcome aboard, roving voyager. Upon the shores lay the silent
            embers of a world during the Ebb Tide. Desolated by the Lament, the
            erstwhile creations and earthly beings are left static. But they
            strike back, strong enough to penetrate the silence. Humanity has
            risen anew from the ashes of the apocalypse.
          </p>
          <p>
            And you, Rover, are poised for an adventure of Awakening. Companions
            to meet, enemies to conquer, new powers to gain, hidden truths to
            unveil, and unseen spectacles to behold... A vast world of endless
            possibilities awaits. The choice rests in your hands. Be the answer,
            be the leader, and follow the sounds to arrive at a new future. As
            Wuthering Waves echo endlessly, mankind set sail on a new journey.
          </p>
          <p>Rise and embark on your odyssey, Rover.</p>
        </blockquote>
        <SectionHeader title="System Requirements" />
        <p>
          Wuthering Waves will be available to play on{' '}
          <strong>PC, and Android / iOS devices</strong>. While the developers
          confirmed that there are plans to release Wuthering Waves for
          consoles, it won't be available at the launch of the game on the 22th
          of May.
        </p>
        <h5>PC - minimum system requirements</h5>
        <ul>
          <li>OS version: Windows 10 64bit or Windows 11 64bit</li>
          <li>CPU: intel i5 (9th Gen) / Ryzen 2700</li>
          <li>Memory: 16GB</li>
          <li>GPU: GTX 1060 / RX 570</li>
          <li>Storage: 30GB</li>
        </ul>
        <h5>PC - recommended system requirements</h5>
        <ul>
          <li>OS version: Windows 10 64bit or Windows 11 64bit</li>
          <li>CPU: intel i7 (9th Gen) / Ryzen 3700</li>
          <li>Memory: 16GB and Above</li>
          <li>GPU: GTX 2060 / RX 5700XT and Above</li>
          <li>Storage: 30GB</li>
        </ul>
        <h5>Supported Languages</h5>
        <p>
          <strong>Audio</strong>: Chinese (Simplified), Japanese, Korean,
          English.
        </p>
        <p>
          <strong>Text</strong>: English, Chinese (Simplified), Chinese
          (Traditional), Japanese, Korean, French, German, Spanish (Spain).
        </p>
        <SectionHeader title="Exploration" />
        <StaticImage
          src="../../../images/ww/generic/exploration.webp"
          alt="Exploration"
        />
        <p>
          Exploration is very important part of Wuthering Waves. Players are
          able to roam around and explore the various areas of the Wuthering
          Waves map for treasure chests, enemies, puzzles, and more.{' '}
          <strong>
            It is important to explore the map as much as possible for faster
            progression as opening chests, doing side quests and beating bosses
            for the first time will reward you with Union level exp, Astrite and
            other goodies.
          </strong>
        </p>
        <p>
          When exploring the world, you will select one resonator out of the
          three in your team to be your active character. The active resonator
          is the one on screen that you control. You can switch the active
          resonator anytime.
        </p>
        <p>
          Outside of the town areas, there will be enemies roaming around the
          map that will aggro and chase you if you get too close to them.
        </p>
        <SectionHeader title="Combat" />
        <StaticImage
          src="../../../images/ww/generic/combat.webp"
          alt="Combat"
        />
        <p>
          Combat in Wuthering Waves is very dynamic. While you can build teams
          made out of 3 Resonators (characters), only one will be available on
          the field, but you can switch between them at any time. And the switch
          mechanic is actually integrated into the characters kits as each has
          an Intro and Outro skill that activate when they appear on the field
          or leave it (more about them later).
        </p>
        <p>
          Overall, it's better to showcase the combat, rather than try to
          explain it using words, so here's a video for you:
        </p>
        <Row className="video-row">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="x2Y36ja0vXA" />
          </Col>
        </Row>
        <SectionHeader title="Gacha system" />
        <StaticImage
          src="../../../images/ww/generic/reroll_1.webp"
          alt="Guides"
        />
        <p>
          Wuthering Waves is a <strong>gacha game</strong> which means that to
          obtain new characters and weapons, you will have to 'pull' them using
          in-game currency (which can also be purchased using real life money).
          The rate for pulling the highest rarity character (
          <strong className="ww-color-5">5★</strong>) are pretty low - sitting
          at <strong>0.8%</strong>, so do expect a lot of grinding to obtain who
          you want.
        </p>
        <p>You can find more information about the gacha system here:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Gacha system"
            link="/wuthering-waves/guides/gacha"
            image={
              <StaticImage
                src="../../../images/ww/categories/category_gacha.jpg"
                alt="Gacha system"
              />
            }
          />
        </Row>
        <SectionHeader title="Resonators" />
        <div className="employee-container for-nikke">
          <WWCharacter mode="icon" slug="verina" enablePopover showLabel />
          <WWCharacter mode="icon" slug="encore" enablePopover showLabel />
          <WWCharacter mode="icon" slug="danjin" enablePopover showLabel />
          <WWCharacter mode="icon" slug="baizhi" enablePopover showLabel />
        </div>
        <p>
          Resonators are the playable units in Wuthering Waves. They are mainly
          obtained via Convenes, the game’s gacha system. However, some
          Resonators can be obtained for free via in-game missions or events.
        </p>
        <h5>Resonator Stats</h5>
        <ul>
          <li>
            <strong>HP</strong> - How much damage a Resonator can take before
            dying,
          </li>
          <li>
            <strong>ATK</strong> - How much damage a Resonator deals,
          </li>
          <li>
            <strong>DEF</strong> - Reduces the damage a Resonator takes,
          </li>
          <li>
            <strong>Max Stamina</strong> - Determines how many continuous
            stamina using actions (dashing, dodging, climbing, swimming and some
            attacks use stamina) you can take before being forcibly stopped,
          </li>
          <li>
            <strong>Crit Rate</strong> - How likely it is for the Resonator to
            land a critical hit,
          </li>
          <li>
            <strong>Crit DMG</strong> - Determines the damage multiplier when
            landing a critical hit,
          </li>
          <li>
            <strong>Energy Regen</strong> - Determines how fast a Resonator
            charges energy for their Resonance Liberation (Ultimate),
          </li>
          <li>
            <strong>Resonance Skill DMG Bonus</strong> - Increases the damage of
            Resonance Skill,
          </li>
          <li>
            <strong>Basic Attack DMG Bonus</strong> - Increases the damage of
            Basic Attacks,
          </li>
          <li>
            <strong>Heavy Attack DMG Bonus</strong> - Increases the damage of
            Heavy Attacks,
          </li>
          <li>
            <strong>Resonance Liberation DMG Bonus</strong> - Increases the
            damage of Resonance Liberation (Ultimate),
          </li>
          <li>
            <strong>Elemental DMG Bonus</strong> - Increases all damage a
            character deals. Note that there are six different elements in the
            game and each element has its own separate Elemental DMG Bonus stat,
          </li>
          <li>
            <strong>Elemental DMG RES</strong> - Reduces the damage a Resonator
            takes from a specific Element,
          </li>
          <li>
            <strong>Healing Bonus</strong> - Increases the amount of healing a
            Resonator provides.
          </li>
        </ul>
        <h5>Resonator Elements & Weapons</h5>
        <p>
          Each Resonator in Wuthering Waves has an <strong>Element</strong>{' '}
          associated with them. A Resonator’s Element determines the damage type
          of their attacks.
        </p>
        <p>There are currently 6 Elements in the game:</p>
        <ul>
          <li>
            <strong className="Glacio">Glacio</strong>
          </li>
          <li>
            <strong className="Fusion">Fusion</strong>
          </li>
          <li>
            <strong className="Electro">Electro</strong>
          </li>
          <li>
            <strong className="Aero">Aero</strong>
          </li>
          <li>
            <strong className="Spectro">Spectro</strong>
          </li>
          <li>
            <strong className="Havoc">Havoc</strong>
          </li>
        </ul>
        <p>
          Each Resonator in Wuthering Waves uses a{' '}
          <strong>specific Weapon type</strong>. A Resonator’s Weapon type will
          give you a general idea as to how they fight as well as which Weapons
          they can equip.
        </p>
        <p>There are currently 5 Weapon types in the game:</p>
        <ul>
          <li>Broadblade</li>
          <li>Gauntlets</li>
          <li>Pistols</li>
          <li>Rectifier</li>
          <li>Sword</li>
        </ul>
        <h5>Resonator Skills</h5>
        <p>
          Each Resonator in Wuthering Waves has 6 different abilities that
          define their playstyle.
        </p>
        <ul>
          <li>
            <strong>Basic Attack</strong> is a Character’s auto-attack and is
            always available. It’s generally the most used ability and it is
            usually the main way to generate the various resources other
            abilities use. You can also activate Heavy Attack by long-pressing
            the Basic Attack button.
          </li>
          <li>
            <strong>Resonance Skill</strong> is a combat ability that a
            Resonator can use with effects ranging from dealing damage to
            healing your allies. This ability usually has a cooldown and can not
            be used back to back.
          </li>
          <li>
            <strong>Resonance Liberation</strong> is generally their strongest
            combat ability. To use Resonance Liberation, it must first be
            charged through certain combat actions such as dealing damage to
            enemies or triggering Extreme Evasion. It must also be recharged
            after every use.
          </li>
          <li>
            <strong>Forte Circuit</strong> is a gameplay defining ability that
            is unique for every Resonator. It is represented by a gauge above
            the health bar. Once it is available, it will change into the color
            of that Resonator’s Element and will gain a waveform-like
            appearance. It can be activated by using the right abilities while
            it is available.
          </li>
          <li>
            <strong>Outro and Intro Skills</strong> are abilities that activate
            upon switching Resonator once the current Resonator has filled the
            Concerto Energy. It is represented by a gauge to the left of the
            health bar. Upon switching, the current Resonator will activate
            their Outro Skill, buffing the next Resonator you are switching
            into. While Intro Skills are unique attacks that the next Resonator
            will perform.
          </li>
        </ul>
        <SectionHeader title="Resonator Progression" />
        <StaticImage src="../../../images/ww/generic/reso_1.webp" alt="Guide" />
        <p>
          Upgrading your Resonators in Wuthering Waves will enhance their combat
          capabilities, allowing you to challenge and overcome the more
          difficult content that the game offers.
        </p>
        <p>
          The main ways for a Resonator to grow stronger in Wuthering Waves are:
        </p>
        <ul>
          <li>Increasing the Resonator level</li>
          <li>Upgrading the Resonator’s Fortes</li>
          <li>Unlocking the Resonator’s Resonance Chain</li>
          <li>Equipping Weapons</li>
          <li>Equipping Echoes</li>
        </ul>
        <h5>Character Level and Ascension</h5>
        <p>
          Each Resonator in Wuthering Waves has a{' '}
          <strong>Resonator Level ranging from 1 to 90</strong> and gains
          additional stats as they level up.
        </p>
        <p>
          Once a Resonator reaches certain level milestones, they will need to
          be ascended in order to increase their level further. Resonators start
          with a base max Level of 20. Each time you ascend a Resonator, their
          level cap will increase by 10 up to a maximum of 90. To ascend a
          Resonator, you will need to gather materials from Boss Challenges and
          collect materials from enemies and plants in the overworld.
        </p>
        <p>
          Also, note that your Union Level (Account Level) will also determine
          how many times you can ascend a Resonator. Even if you have all of the
          required ascension materials, you will not be able to ascend a
          Resonator past a certain Level unless your account is also of the
          appropriate Union Level.
        </p>
        <h5>Fortes</h5>
        <StaticImage src="../../../images/ww/generic/fortes.webp" alt="Guide" />
        <p>
          <strong>
            Fortes are a skill upgrade tree that each Resonator has
          </strong>
          . Unlocking and upgrading the various nodes in a Resonator’s Forte
          will provide them with stat bonuses, extra passive abilities, and
          enhancements to their 5 Skills.
        </p>
        <p>
          Fortes can be upgraded by using materials obtained by completing
          Forgery Challenges, Weekly Challenges, and killing overworld enemies.
          Also, note that the ability to upgrade Fortes is tied to your
          Resonator’s Ascension, so you will need to level your Resonator first
          before being able to upgrade their Fortes.
        </p>
        <h5>Resonance Chain/Wavebands</h5>
        <StaticImage src="../../../images/ww/generic/reso_2.webp" alt="Guide" />
        <p>
          Resonance Chain enhances Resonator’s abilities or provides them with
          new passive bonuses. Each Resonator has 6 Resonance Chain upgrades
          with each upgrade providing a different bonus.
        </p>
        <p>
          Resonance Chain upgrades can be unlocked by obtaining duplicate copies
          (called Wavebands) of the Resonator from Convenes (the gacha system)
          or through in-game missions and events. Though compared to other
          games, you can also{' '}
          <strong>buy Wavebands from the in-game shop</strong> using a currency
          you obtain from pulling - but the shop limits the amount of Wavebands
          you can buy to 2 per character (and this includes the current rate-up
          characters).
        </p>
        <p>
          The only exception to this is the Main Character. You can obtain their
          Wavebands via in-game missions and Relic Merchant rewards.
        </p>
        <h5>Weapons</h5>
        <StaticImage src="../../../images/ww/generic/reso_3.webp" alt="Guide" />
        <p>
          Weapons are a type of equipment that all Resonators can equip and they
          provide stat bonuses to the Resonator and a new passive.{' '}
          <strong>
            {' '}
            Similar to Resonators, Weapons also have a Level ranging from 1 to
            90
          </strong>{' '}
          . They can be leveled up and ascended as well. Doing so will increase
          the stat bonus that they provide to the equipped Resonator.
        </p>
        <p>
          Each Weapon also has a Weapon ability which is a passive bonus. A
          Weapon Ability starts at Rank 1 and can be upgraded to Rank 5 by using
          the <strong>Synthesis</strong> function.{' '}
        </p>
        <p>
          To use the Synthesis function, you will need to expend duplicate
          Weapons of the one that you are upgrading. Each duplicate Weapon that
          you use will increase the Weapon’s Ability Rank by 1. Note that any
          Weapons used as a Synthesis material will be consumed and destroyed.
          Increasing the Rank of a Weapon Ability will enhance some or all of
          the numerical portion of the bonus that it provides.
        </p>
        <p>
          <strong>
            Keep in mind that Weapons can only be equipped if the Resonator can
            use that weapon type.
          </strong>
        </p>
        <h5>Echoes</h5>
        <StaticImage src="../../../images/ww/generic/reso_4.webp" alt="Guide" />
        <p>
          <strong>
            Echoes are the other type of equipment in this game and serve the
            function of armor and accessories in a traditional RPG game.
          </strong>{' '}
          Equipping Echoes to your Resonator will provide them with a variety of
          stat increases from HP to ATK to Energy Regeneration. Echoes will
          provide your Resonators with the main bulk of their stat increases.{' '}
        </p>
        <ul>
          <li>
            Every Echo has an <strong>active Echo ability</strong>.
          </li>
          <li>
            <strong>There are 5 total Echo slots</strong>. The Echo equipped in
            the first slot will have its active Echo Ability available for you
            to use. The order of the rest does not matter.
          </li>
          <li>
            Each Echo comes with a COST requirement that ranges between 1 to 4
            depending on the Echo’s Class.{' '}
            <strong>
              Each Resonator has a COST limit of 10 at the start and it is
              possible to upgrade it to 12 through Data Dock upgrades.
            </strong>{' '}
            Resonators can only equip Echoes up to the COST limit.
          </li>
        </ul>
        <p>There are 4 different Echo Classes:</p>
        <ul>
          <li>
            <strong className="Common">Common Class</strong> (1-COST)
          </li>
          <li>
            <strong className="Elite">Elite Class</strong> (3-COST)
          </li>
          <li>
            <strong className="Overlord">Overlord Class</strong> (4-COST)
          </li>
          <li>
            <strong className="Calamity">Calamity Class</strong> (4-COST)
          </li>
        </ul>
        <p>
          Each Echo has 2 main stats and up to 5 sub-stats. Primary main stats
          differ while secondary main stats are set for each Echo Class.
          Depending on the Echo Class, the possible{' '}
          <strong>primary main stats</strong> are:{' '}
        </p>
        <ul>
          <li>
            <strong className="Common">Common Class</strong> - HP%, ATK%, DEF%
          </li>
          <li>
            <strong className="Elite">Elite Class</strong> - HP%, ATK%, DEF%,
            Energy Regen%, Elemental Damage Boost*
          </li>
          <li>
            <strong className="Overlord">Overlord Class</strong> - HP%, ATK%,
            DEF% Crit Rate%, Crit DMG%, Healing Bonus%
          </li>
          <li>
            <strong className="Calamity">Calamity Class</strong> - HP%, ATK%,
            DEF% Crit Rate%, Crit DMG%, Healing Bonus%
          </li>
        </ul>
        <p>
          *Note that Elemental Damage Boost will be one of the 6 possible
          elements. It is not an All Elemental Damage Boost.
        </p>
        <p>
          Depending on the Echo Class, the set{' '}
          <strong>secondary main stats</strong> are:
        </p>
        <ul>
          <li>
            <strong className="Common">Common Class</strong> - Flat HP
          </li>
          <li>
            <strong className="Elite">Elite Class</strong> - Flat ATK
          </li>
          <li>
            <strong className="Overlord">Overlord Class</strong> - Flat ATK
          </li>
          <li>
            <strong className="Calamity">Calamity Class</strong> - Flat ATK
          </li>
        </ul>
        <p>
          To learn more about Echoes, Echo Stats and Echo Sets, check our other
          guides:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Echo System"
            link="/wuthering-waves/guides/echoes-explained"
            image={
              <StaticImage
                src="../../../images/ww/categories/category_echo.png"
                alt="Echo system explained"
              />
            }
          />
          <CategoryCard
            title="Echo Sets"
            link="/wuthering-waves/guides/echo-sets"
            image={
              <StaticImage
                src="../../../images/ww/categories/category_echo4.png"
                alt="Echo sets"
              />
            }
          />
          <CategoryCard
            title="Echo Stats"
            link="/wuthering-waves/guides/echo-stats"
            image={
              <StaticImage
                src="../../../images/ww/categories/category_echo3.png"
                alt="Echo stats"
              />
            }
          />
        </Row>
        <h5>Collecting Echoes</h5>
        <StaticImage
          src="../../../images/ww/generic/echo_capture.webp"
          alt="Guide"
        />
        <p>
          When a Tacet Discord (monster enemies) is defeated, there is a chance
          that it will leave an Echo behind for you to absorb. The drop rate and
          the rarity of the Echo absorbed depends on your Data Dock level.
        </p>
        <p>
          <strong>Enemies in the world will reset daily.</strong> This timer is
          unique to each world so it is possible to join another person’s world
          to farm more Echoes (keep in mind that co-op unlocks at Union Level
          30). The loot in co-op is personal so every player in the instance has
          a chance to get an Echo after a kill.{' '}
          <strong>
            This means that you can infinitely and more effectively farm Echoes
            by grouping up with other players and rotating worlds.
          </strong>
        </p>
        <SectionHeader title="Game Systems" />
        <p>Information about various systems available in Wuthering Waves.</p>
        <h5>Union Level</h5>
        <StaticImage
          src="../../../images/ww/generic/system_6.webp"
          alt="Guide"
        />
        <p>
          <strong>Union Level is your overall account level</strong> and can be
          increased by obtaining Union EXP. Union EXP can primarily be earned by
          completing in-game missions and events as well as spending Waveplate
          (the game’s stamina system).
        </p>
        <p>
          As your Union Level increases, you will unlock more game functions
          such as Daily Activities as well as increasing the number of times
          that you can ascend your Resonators and Weapons, increasing their
          level cap.{' '}
          <strong>
            Additionally, the difficulty of the enemy in the overworld will also
            increase once you reach certain Union Level milestones. The maximum
            Union level is 60.
          </strong>
        </p>
        <p>
          As Union Level increases, the SOL3 Phase also increases, making
          enemies more challenging while also increasing rewards. SOL3 Phase
          increases every 10 Union Level.{' '}
          <strong>
            You can change your SOL3 Phase between the ones you unlocked every
            24 hours.
          </strong>
        </p>
        <h5>Waveplate</h5>
        <StaticImage
          src="../../../images/ww/generic/system_4.webp"
          alt="Guide"
        />
        <p>
          <strong>Waveplate is the stamina system of Wuthering Waves</strong>.
          It is primarily used to obtain resources and upgrade materials from
          the various resource and boss dungeons in the game. Waveplate is
          generated at a rate of 1 per 6 minutes. You can store up to a maximum
          of 240. A total of 240 Waveplate can be generated every day.
        </p>
        <p>
          You can also replenish your Waveplate by using Crystal Solvent (reward
          from various missions) or Astrites. Crystal Solvent is an in-game
          consumable that when used will give you 60 Waveplate immediately.
        </p>
        <h5>Quests</h5>
        <StaticImage
          src="../../../images/ww/generic/system_1.webp"
          alt="Guide"
        />
        <p>Quests in Wuthering Waves come in a couple of different variety:</p>
        <ul>
          <li>
            <strong>Main:</strong>
          </li>
          <ul>
            <li>
              They are denoted with a yellow color in your quest log and on the
              mini map.
            </li>
            <li>
              They reward Astrites, the game’s gacha currency, and huge amounts
              of Union EXP.
            </li>
          </ul>
          <li>
            <strong>Companion:</strong>
          </li>
          <ul>
            <li>
              Companion Quests are side quests for specific Resonators. These
              are generally missions that will provide you with more background
              and lore on the Character that they are centered on. They are
              denoted with a purple color in your quest log and on the mini map.
            </li>
            <li>They reward Astrites and Union EXP.</li>
          </ul>
          <li>
            <strong>Side:</strong>
          </li>
          <ul>
            <li>
              These are Side Quests as the name suggests. They are denoted with
              a blue color in your quest log and on the mini map. Some will
              reward Astrites.
            </li>
          </ul>
          <li>
            <strong>Exploration:</strong>
          </li>
          <ul>
            <li>
              Exploration Quests are regional quests. These introduce you to new
              regions while providing lore. They are denoted with a green color
              in your quest log and on the mini map.
            </li>
          </ul>
          <li>
            <strong>Activities:</strong>
          </li>
          <ul>
            <li>Activities are your daily missions.</li>
            <li>
              You will receive a semi-randomized set of 7 Activities every day.
            </li>
            <li>
              Each Training mission will provide you with 10 or 20 Activity
              Points and you need to gather 100 in total to obtain all daily
              rewards which includes a total of 60 Astrites as well as some
              upgrade materials.
            </li>
          </ul>
        </ul>
        <h5>Drifting Inscription</h5>
        <StaticImage
          src="../../../images/ww/generic/system_2.webp"
          alt="Guide"
        />
        <p>
          Drifting Inscription is a set of beginner missions that are designed
          to guide the player’s progression at the start of the game. Completing
          these missions will reward you with various resources.
        </p>
        <p>
          There are 6 pages of beginner missions in the Drifting Inscription.
        </p>
        <h5>Trophies</h5>
        <StaticImage
          src="../../../images/ww/generic/system_3.webp"
          alt="Guide"
        />
        <p>
          As the name suggests, Trophies are a collection of tasks that the
          player can complete to earn a varying amount of Astrites
        </p>
        <h5>Pioneer Podcast</h5>
        <StaticImage
          src="../../../images/ww/generic/system_5.webp"
          alt="Guide"
        />
        <p>
          <strong>Pioneer Podcast is the battle pass of Wuthering Waves</strong>
          . It has a free and premium (that can be unlocked with real money)
          path. Players can get rewards from these paths by completing daily,
          weekly, and periodical missions. Pioneer Podcast resets periodically
          (most likely every 6 weeks).
        </p>
        <p>
          The price of the{' '}
          <strong>
            Premium version (called Insider Channel) was set at $10
          </strong>
          , and you also were able to buy an advanced version that unlocked some
          of the rewards early for $20. Furthermore, the Insider Channel comes
          with a <strong className="ww-color-4">4★</strong>{' '}
          <strong>weapon selector</strong> and those weapons can only be
          obtained from it (so it's the same as in Honkai: Star Rail).
        </p>
        <SectionHeader title="Game Modes" />
        <p>
          In addition to exploring the overworld and doing missions, Wuthering
          Waves also offers a number of other game modes for the player to enjoy
          as well.
        </p>
        <h5>Resource/Boss Stages</h5>
        <p>
          Throughout the overworld in Wuthering Waves, you will encounter a
          number of dungeon type entrances or special fields that you can enter
          to farm resources and materials to progress your account.{' '}
          <strong>
            However, Waveplate (stamina) must be expended in order to obtain the
            rewards for completing these stages.
          </strong>
        </p>
        <p>The different stages are:</p>
        <h6>Simulation Field</h6>
        <StaticImage src="../../../images/ww/generic/mode_4.webp" alt="Guide" />
        <p>
          These are your basic resource stages and provide one of the following
          material depending on which Simulation Field you are farming:
        </p>
        <ul>
          <li>Simulation: Resonance Potion - Resonator EXP Material</li>
          <li>Simulation: Energy Core - Weapon EXP Material</li>
          <li>Simulation: Shell Credits - Shell Credits(in game money)</li>
          <li>Simulation: Sealed Tube - Echo EXP Material</li>
        </ul>
        <p>
          It costs 40 <strong>Waveplate</strong> to do one Simulation Field.
        </p>
        <h6>Forgery Challenge</h6>
        <StaticImage src="../../../images/ww/generic/mode_8.webp" alt="Guide" />
        <p>
          These stages reward Weapon Ascension/Forte Upgrade materials. There
          are 5 stages that drop different materials in correspondence with the
          5 different Weapon types, so make sure to check your Resonator page to
          see which one you need.
        </p>
        <p>
          It costs <strong>40 Waveplate</strong> to do one Forgery Challenge.
        </p>
        <h6>Boss Challenge</h6>
        <StaticImage src="../../../images/ww/generic/mode_6.webp" alt="Guide" />
        <p>
          These are Bosses that reward Resonator ascension materials. Each boss
          drops a different ascension material, so make sure to check your
          Resonator page to see which one the Resonator you are ascending needs.
        </p>
        <p>Bosses will respawn 3 minutes after being defeated.</p>
        <p>
          It costs <strong>60 Waveplate</strong> to claim rewards from Boss
          Challenge one time.
        </p>
        <h6>Tacet Field Cleanup</h6>
        <StaticImage src="../../../images/ww/generic/mode_2.webp" alt="Guide" />
        <p>
          These fields reward Echo Tune-up materials, Echoes, and Echo EXP
          materials. Each field reward Echoes with different Sonata(Set) Effects
          so make sure pick the ones with the Sonata Effects you want.
        </p>
        <p>
          It costs <strong>60 Waveplate</strong> to claim rewards from Tacet
          Field Cleanup one time.
        </p>
        <h6>Weekly Challenge</h6>
        <StaticImage src="../../../images/ww/generic/mode_5.webp" alt="Guide" />
        <p>
          These are weekly bosses that reward special Forte upgrade materials,
          standard weapon molds (used for weapon crafting), Weapon EXP
          materials, and Echo EXP materials.
        </p>
        <p>You can only claim rewards from these bosses once a week.</p>
        <p>
          It costs <strong>60 Waveplate</strong> to claim rewards from Weekly
          Challenge once.
        </p>
        <h5>Tower of Adversity</h5>
        <StaticImage src="../../../images/ww/generic/mode_1.webp" alt="Guide" />
        <p>
          <strong>Tower of Adversity</strong> is a game mode where the objective
          is to defeat a group of enemies as fast as possible. After completing
          a Tower of Adversity stage you will be awarded between 0 to 3 crests
          for that stage depending on how long it took you to defeat the enemies
          in that stage. The more crests that you are awarded, the better the
          rewards you receive.
        </p>
        <p>
          It is a more challenging game mode with the{' '}
          <strong>final stages being considered as end game content</strong>.
        </p>
        <p>
          Tower of Adversity is divided into three parts:{' '}
          <strong>Stable Zone, Experimental Zone, and Hazard zone.</strong>
        </p>
        <ul>
          <li>
            <strong>Stable Zone</strong> consists of 4 stages and provides a one
            time reward of Astrites upon completion.
          </li>
          <li>
            <strong>Experimental Zone</strong> consists of 8 stages and also
            provides a one time reward of Astrites upon completion.
          </li>
          <ul>
            <li>
              It is not possible to clear all stages using the same Resonators
              due to Vigor limitations (explained below).
            </li>
          </ul>
          <li>
            <strong>Hazard Zone</strong> consists of 10 stages and reward
            Astrites upon completion.
          </li>
          <ul>
            <li>Hazard Zone stages are more challenging than other zones.</li>
            <li>
              Hazard Zone stages reset periodically, allowing the player to
              complete them again for additional Astrites.
            </li>
          </ul>
        </ul>
        <p>
          <strong>
            Basically, this mode is the Wuthering Waves equivalent of Abyss from
            Genshin Impact or Memory of Chaos from Honkai: Star Rail.
          </strong>
        </p>
        <h6>Vigor</h6>
        <StaticImage src="../../../images/ww/generic/mode_3.webp" alt="Guide" />
        <p>
          <strong>
            Vigor is a stamina system unique to every zone in the Tower of
            Adversity
          </strong>
          .
        </p>
        <ul>
          <li>Each Resonator starts with 10 Vigor.</li>
          <li>
            Each stage in a zone has a Vigor cost requirement. Selected
            Resonators’ Vigor will be reduced by the stage’s Vigor cost upon
            completing the stage.
          </li>
          <li>
            Resonators that don’t have enough Vigor for a stage cannot be
            selected for that stage.
          </li>
        </ul>
        <h5>Depths of Illusive Realm</h5>
        <StaticImage src="../../../images/ww/generic/mode_7.webp" alt="Guide" />
        <p>
          <strong>
            Depths of Illusive Realm is a roguelike game mode where the player
            will select a Resonator to venture inside a simulated world to
            collect a random set of buffs that they can use to enhance their
            team and fight the enemies inside.
          </strong>{' '}
          The player will progress through a set of semi-randomized combat and
          non-combat stages with the goal of defeating the boss in the final
          stage. This game mode does not require the player to spend any
          Waveplate to play it and there is no limit to how many times the
          player can start a run in the Depths of Illusive Realm.
        </p>
        <p>
          Players can earn rewards such as Astrites and upgrade materials
          periodically via this game mode. Note that this game mode is currently
          in beta and it is highly possible that it will see drastic changes
          upon actual release.
        </p>
        <p>
          <strong>
            Basically, this mode is the Wuthering Waves equivalent of Simulated
            Universe from Honkai: Star Rail.
          </strong>
        </p>
        <h5>Tactical Hologram</h5>
        <p>
          Tactical Holograms are 1-time boss challenges with increasing levels
          of difficulty.
        </p>
        <ul>
          <li>
            There are currently 4 different bosses each with 6 difficulty
            levels.
          </li>
          <li>
            Clearing these boss challenges for the first time will reward you
            with various Echo related materials and Drill Data Sets.
          </li>
          <li>
            You can use these Drill Data Sets in store to obtain more Echo
            related materials and Astrites!
          </li>
        </ul>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default WWGuidesBeg;

export const Head: React.FC = () => (
  <Seo
    title="Beginner guide | Wuthering Waves | Prydwen Institute"
    description="A beginner guide for Wuthering Waves that will help you play the game optimally!"
    game="ww"
  />
);
